/*  Hotellab Reservation Widget JS
    Created By: Hotellab - Burak Efe AYVAZLAR
    Created Date: 27.12.2023
*/

$(".call-now-content").hover(function () {
    $(".call-nov-link").addClass("link-active");
},

function () {
    $(".call-nov-link").removeClass("link-active"); 
});

$(".whatsapp-content").hover(function () {
    $(".whatsapp-link").addClass("link-active");
},
    
function () {
     $(".whatsapp-link").removeClass("link-active"); 
});

$(".reservation-content").hover(function () {
        $(".reservation-link").addClass("link-active");
},

function () {
        $(".reservation-link").removeClass("link-active");
});
    
$(".geri-don").hover(function () {
     $(".geri-don-link").addClass("link-active");
},

function () {
     $(".geri-don-link").removeClass("link-active"); 
});